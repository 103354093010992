<template>
  <div>
    <b-row
      v-if="isLoading"
      class="mt-4"
    >
      <b-col>
        <div v-if="isLoading">
          <b-overlay
            v-if="isLoading"
            :show="isLoading"
            :rounded="overlayConfig.rounded"
            :variant="overlayConfig.variant"
            :opacity="overlayConfig.opacity"
            :blur="overlayConfig.blur"
          >
            <template #overlay>
              <div
                v-if="isLoading"
                class="mt-2"
              >
                <div style="text-align: center">
                  <span class="spinner-border p-5" />
                </div>
                <div style="text-align: center">
                  <small>{{ overlayConfig.message }}</small>
                </div>
              </div>
            </template>
          </b-overlay>
        </div>
      </b-col>
    </b-row>
    <div v-if="!isLoading">
      <b-card v-if="validations">
        <b-row class="justify-content-md-center">

          <feather-icon
            slot="button-content"
            :icon="processFailed? 'AlertTriangleIcon': 'InfoIcon'"
            size="50"
          />

        </b-row>
        <br>
        <b-row class="justify-content-md-center">

          <span v-if="failedAfiliaiteValidation"> Para solicitar beneficios tu afiliación debe estar activa </span>
          <span v-if="errorWs"> No hemos podido validar tu estado de afiliación. Intentalo más tarde </span>
          <span v-if="processFailed"> Error de comunicación. Intente mas tarde </span>

        </b-row>
        <hr>
        <b-row class="justify-content-md-center">
          <b-button
            v-if="processFailed || errorWs"
            squared
            size="sm"
            variant="outline-secondary"
            @click="reintentar()"
          >
            Reintentar
          </b-button>
        </b-row>
      </b-card>
      <b-card v-if="!validations">
        <b-row class="justify-content-md-center">
          <b-col md="3">
            <h4>Panel de beneficios</h4>
          </b-col>
        </b-row>
        <br>
        <b-row
          v-if="beneficios.length===0"
          class="justify-content-md-center"
        >

          <b-row class="justify-content-md-center">
            <b-row>
              <b-col md="1">
                <feather-icon
                  slot="button-content"
                  icon="AlertTriangleIcon"
                  size="50"
                />
              </b-col>
              <b-col
                md="11"
                class="mt-1"
              >
                <span> En este momento no hay beneficios disponibles para solicitar</span>
              </b-col>
            </b-row>
          </b-row>

        </b-row>
        <b-row class="justify-content-md-center">
          <b-card-group
            v-for="(beneficio) in beneficios"
            :key="beneficio.id"
            deck
            class="col-md-3 col-6 my-1 text-center"
          >
            <!--class="mb-2"
          style="max-width: 20rem;"
          img-src="@/assets/images/beneficios/caja.jpg"
          -->
            <b-card
              v-b-hover
              img-alt="Card image"
              img-top
              no-body
              class="overflow-hidden"
              style="max-width: 540px;"
              border-variant="primary"
            >
              <b-card-text class="mt-1">
                <p>{{ beneficio.nombre }}</p>
                <small v-if="beneficio.controlarVigencia"> Vigente hasta el {{ formatDateTable(beneficio.vigenciaHasta, 'DD/MM/YYYY') }}</small>
                <small v-else><br></small>
              </b-card-text>
              <template #footer>
                <b-button
                  href="#"
                  squared
                  size="sm"
                  variant="primary"
                  @click="solicitar(beneficio)"
                >
                  Ingresar
                </b-button>
              </template>
            </b-card>
          </b-card-group>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { solicitudResource } from '@/services/solicitudResource'

export default {
  components: {
  },
  data() {
    return {
      isLoading: false,
      processFailed: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: 'Cargando beneficios',
      },
      failedAfiliaiteValidation: false,
      errorWs: false,
      nameModulo: 'SOLICITUD_BENEFICIO',
      beneficios: [],
    }
  },

  computed: {
    validations() {
      if (!this.errorWs && !this.failedAfiliaiteValidation && !this.processFailed) {
        return false
      }
      return true
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.getBeneficios()
  },
  methods: {
    reintentar() {
      // this.beneficios = []
      // this.getBeneficios()
      window.location.reload()
    },
    solicitar(beneficio) {
      this.$router.push(`/panelbeneficios/view/${beneficio.id}`)
    },
    getBeneficios() {
      this.isLoading = true
      this.errorWs = false
      this.failedAfiliaiteValidation = false
      solicitudResource().findBeneficios().then(resultGetBeneficios => {
        this.beneficios = resultGetBeneficios
      }).catch(error => {
        if (error !== undefined) {
          if (error.data.error.includes('Error servicio WS')) {
            this.errorWs = true
            console.error('Error servicio WS')
          } else if (error.data.code.includes('"INTERNAL_SERVER_ERROR"')) {
            console.error('INTERNAL_SERVER_ERROR')
          } else {
            this.failedAfiliaiteValidation = true
          }
        } else {
          this.processFailed = true
        }
        console.error(error)
      })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
<style>
</style>
